import Slider from "../components/slider";
import NavBar from "../components/nav";
import Products from "../components/products";
import Knowledge from "../components/knowledge";
import About from "../components/footer";

const Home = () => {
  return (
    <>
      <NavBar />
      <Slider />
      <Products />
      <Knowledge />
      <About />
    </>
  );
};

export default Home;
