import { useParams } from "react-router-dom";
import { useState } from "react";
import { mine_images } from "./mine-images";

const Mequipment = () => {
  let { id } = useParams();
  let [cur, setCur] = useState(mine_images);
  const [prev] = useState(mine_images);
  const [data_text, setData_Text] = useState("");
  const filterData = (e) => {
    e.preventDefault();
    if (cur === "") {
      setCur(prev);
    }
    // let data = cur.filter((eq) => eq.text === itemValue);
    //setCur(data);
    ///window.location.href = "#/" + data_text;
    /// window.location.href = "#/mining-equipment/" + data_text;
    let data = cur.filter((eq) => eq.text.includes(data_text));
    setCur(data);
  };

  return (
    <>
      <div className="products" id="prods">
        <h2>Products / Underground drilling</h2>
        <div className="search-bar">
          <form action="" onSubmit={filterData}>
            <input
              type="text"
              id="data_text"
              name="data_text"
              value={data_text}
              onChange={(e) => setData_Text(e.target.value)}
              onFocusCapture={() => setCur(prev)}
            />
            <button type="submit">Search</button>
          </form>
        </div>
        <div className="showcase">
          {cur.map((data) => {
            return (
              <>
                <div className="level">
                  <div className="case-img">
                    <div>
                      <img
                        src={data.img}
                        alt="mine_images"
                        id="products-mining"
                      />
                    </div>
                    <p>{data.text}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Mequipment;
