import NavBar from "../components/nav";
import Brief from "../components/about";
import About from "../components/footer";

const About_us = () => {
  return (
    <>
      <NavBar />
      <Brief />
      <About />
    </>
  );
};

export default About_us;
