const Brief = () => {
  return (
    <>
      <div className="brief">
        <h2>About YOVAN Trading</h2>
        <div className="about-cover">
          <img src="/images/about-cover.jpg" alt="" id="about-cover" />
        </div>
        <h2>Background</h2>
        <p>
          • Yovan Trading (Pvt) Ltd ZW is an indigenous owned company
          established in 2007. • Yovan Trading (Pvt) Ltd SA is an independent
          company under the ownership of Yovan Trading (Pvt) Ltd ZW established
          in 2015 as a dedicated buying house primarily concerned with supply
          chain management of its parent company.
        </p>
        <h2>Our Values</h2>
        <p>
          • Our vision is founded upon the recognition that the success of our
          customers is also our success. Therefore we strive to offer effective
          customer oriented solutions and building upon relations with our
          diverse clientele so as to deliver sustainable value within their
          business. • Customer oriented solutions is our core business.
        </p>
        <h2>our Goal & Mission</h2>
        <p>
          • Our goal is to become the supplier of choice renowned for supplying
          quality and cost effective hardware, mining tools & accessories.
        </p>
        <h2>Our CLIENTELE</h2>
        <div className="clientele-wrap">
          <div className="clientele">
            <img src="/images/img1.jpg" alt="" id="clientele" />
            <img src="/images/img2.jpg" alt="" id="clientele" />
            <img src="/images/img3.jpg" alt="" id="clientele" />
            <img src="/images/load.jpg" alt="" id="clientele" />
          </div>
        </div>
        <h2>Our Key Personel</h2>
        <div className="clientele" id="client-mobile">
          <div>
            <h4>KENNEDY CHARIMBA MANAGING DIRECTOR</h4>
            <p>
              phone : <br />
              +263 772 638 430
              <br />
            </p>
              emails : <br />
              admin@yovantrading.com
          </div>
          <div>
            <h4>MARY CHARIMBA DIRECTOR</h4>
            <p>
              phone : <br />
              +263 772 468 087 <br />
            </p>
              email : <br />
              yovan.trading@gmail.com
          </div>
          <div>
            <h4>LAYTON CHARIMBA GENERAL MANAGER</h4>
            <p>
              phone : +263 773 188 112
              <br />
            </p>
              email : <br />
              purchasing@yovantrading.com
          </div>
          <div>
            <h4>LEYSLIE CHARIMBA FINANCE DIRECTOR</h4>
            <p>
              tel : +278 1754 0954 <br />
            </p>
              email :
              <br />
              leyslie@yovantrading.co.za
          </div>
        </div>
      </div>
    </>
  );
};

export default Brief;
