const Error = () => {
  return (
    <>
      <h2>Error 404 page not found.</h2>
      <p>Cannot diplay page</p>
    </>
  );
};

export default Error;
