import NavBar from "../components/nav";
import Iequipment from "../components/industrial-equp";
import About from "../components/footer";

const Industrial = () => {
  return (
    <>
      <NavBar />
      <Iequipment />
      <About />
    </>
  );
};

export default Industrial;
