export const industrial_images = [
  {
    id: "1",
    img: "images/CD100 Crawler Drill.png",
    text: "CD100 Crawler Drill",
  },
  {
    id: "2",
    img: "images/wagon-drill.jfif",
    text: "Wagon Drill",
  },
  {
    id: "3",
    img: "images/crawler-rig.jpg",
    text: "Crawler Rig",
  },
  {
    id: "4",
    img: "images/anfo.jpg",
    text: "ANFO Lifters",
  },
  {
    id: "5",
    img: "images/MH502 ROCKDRILL.png",
    text: "MH502 ROCKDRILL",
  },
  {
    id: "6",
    img: "images/NB35-Breaker.jpg",
    text: "NB35-Breaker",
  },
  {
    id: "7",
    img: "images/PAVING MACHINE.png",
    text: "PAVING MACHINE",
  },
  {
    id: "8",
    img: "images/Rockdrill s21 blower.jpg",
    text: "Rockdrill s21 / s25 Blower",
  },
  {
    id: "9",
    img: "images/Pick Hammer.png",
    text: "Pick Hammer",
  },
  {
    id: "10",
    img: "images/Core spilitter.jpg",
    text: "Core Splitter",
  },
  {
    id: "11",
    img: "images/DTH Hammer Bit.png",
    text: "DTH Hammer Bits",
  },
  {
    id: "12",
    img: "images/Anfex-Loader.jpg",
    text: "Anfex Loader",
  },
];
