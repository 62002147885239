import "../styles/App.css";
const About = () => {
  return (
    <>
      <div className="about" id="contact">
        <h2>Contact Us</h2>
        <div className="directions">
          <ul>
            <li>
              <b>Harare Branch</b>
            </li>
            <br />
            <li>Stand No. 11725 Bulawayo Road</li>
            <li>Westlea Industrial Park</li>
            <li>Harare Zimbabwe</li>
            <li>Email: sales@yovantrading.com</li>
            <li>Cell: +263 712 399 749</li>
            <li>Tel : +263 242 570 650</li>
            <li>
              VOIP : +263 086 7719 8940
              <br />
              +263 086 8800 7329
            </li>
          </ul>
          <ul>
            <li>
              <b>South Africa</b>
            </li>
            <br />
            <li>Buying house: 319 Willow Crest 14</li>
            <li>Sagewood Avenue Noordwyk</li>
            <li>Midrand Gauteng South Africa</li>
            <li>
              Email: sales@yovantrading.co.za, <br />
              accounts@yovantrading.co.za
            </li>
            <li>Cell: +27745487113 / +27817540954</li>
          </ul>
          <ul>
            <li>
              <b>Headquarters</b>
            </li>
            <br />
            <li>126 Sturgess road</li>
            <li>Cranborne park</li>
            <li>Harare Zimbabwe</li>
            <li>Tel: +263 242 570049</li>
            <li>VOIP: 08677168485</li>
            <li>Email : admin@yovantrading.com</li>
          </ul>
          <ul>
            <li>
              <b>Bulawayo Branch</b>
            </li>
            <br />
            <li>105 Plumtree Road</li>
            <li>Belmount, Bulawayo Zimbabwe</li>
            <li>Email : yovanbyo@gmail.com</li>
            <li>Tel: +263 292 477333</li>
            <li>Cell:+263 712 399 759</li>
          </ul>
        </div>
        <div className="news">
          <div>
            <h3>Subscribe to our news feeds</h3>
            <br />
            <p>website developed by uprisorenterprises.</p>
          </div>
          <div className="foot">
            <input type="text" />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
