import { Link } from "react-router-dom";

/* import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { useEffect } from "react";
*/

const Products = () => {
  /* const animation = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.5,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: "-300vw",
      });
    }
  }, [inView]);
  */
  return (
    <>
      <div className="products" id="prods">
        <h2>Products</h2>
        <div className="showcase">
          <div className="case">
            <div>
              <img
                src="/images/rockdrill machine s25&s215.jpg"
                alt=""
                id="products-cover"
              />
            </div>
            <h2>Underground drilling</h2>
            <p>
              Yovan Trading is not limited to mining equipment we provide mining
              equipment of high quality that include Rockdrill spares, Airleg,
              Steel bits & Retractable Airlegs and more.
            </p>
            <button>
              <Link
                to="/mining-equipment"
                style={{
                  textDecoration: "none",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "22px",
                  paddingRight: "22px",
                  color: "white",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                Explore more
              </Link>
            </button>
          </div>
          <div className="case">
            <div>
              <img
                src="/images/CD100 Crawler Drill.png"
                alt=""
                id="products-cover"
              />
            </div>
            <h2>Surface drilling</h2>
            <p>
              We also provide heavy machinery if you are to dig underground we
              have big machinery equipment that include crawler rigs, Wagon
              drills, Cranes and much more we also have ANFO lifter to lift
              giant rocks and much more
            </p>
            <button>
              <Link
                to="/industrial-equipment"
                style={{
                  textDecoration: "none",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "22px",
                  paddingRight: "22px",
                  color: "white",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                Explore more
              </Link>
            </button>
          </div>
          <div className="case">
            <div>
              <img
                src="/images/core trays AQ,BQ,NQ,HQ,PQ.jpg"
                alt=""
                id="products-cover"
              />
            </div>
            <h2>Mining accessories</h2>
            <p>
              We are not limited to mining accessories we provide Charging
              sticks, claw coupings, core blocks, electrical pumps, core trays,
              coupling sleeves, glen cocks, hose clamps and many other....
            </p>
            <button>
              <Link
                to="/mining-assessories"
                style={{
                  textDecoration: "none",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "22px",
                  paddingRight: "22px",
                  color: "white",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                Explore more
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
