import React from "react";
import { useState, useEffect } from "react";
import { pictures } from "./images";
/* import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
*/

const Slider = () => {
  /* const animation = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.5,
        },
      });
    }
    if (!inView) {
      animation.start({
        y: "-300vh",
      });
    }
  }, [inView]);
  */
  const [currentState, setCurrentState] = useState(0);
  const pos = pictures[currentState];
  let num = currentState;

  /* function Increase() {
    if (currentState >= pictures.length - 1) {
      setCurrentState(0);
    } else {
      setCurrentState(currentState + 1);
    }
  }
  function Decrease() {
    if (currentState < 1) {
      setCurrentState(pictures.length - 1);
    } else {
      setCurrentState(currentState - 1);
    }
  }

  */

  useEffect(() => {
    anime();
  }, [num]);

  function anime() {
    setTimeout(() => {
      if (currentState >= pictures.length - 1) {
        setCurrentState(0);
      } else {
        setCurrentState(currentState + 1);
      }
    }, 3000);
  }

  return (
    <>
      <div className="slider">
        <div className="image">
          <img src={pos.img} alt="miners" id="img" />
          <div className="shadow">
            <h1>{pos.text}</h1>
            <p>Scroll down for more info</p>
            <div>
              <img src="/images/down.png" alt="" />
              <img src="/images/down.png" alt="" />
            </div>
          </div>
        </div>
        <div className="buttons"></div>
      </div>
    </>
  );
};

export default Slider;
