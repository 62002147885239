import Assess from "../components/assesories-comp";
import NavBar from "../components/nav";
import About from "../components/footer";

const Assessories = () => {
  return (
    <>
      <NavBar />
      <Assess />
      <About />
    </>
  );
};

export default Assessories;
