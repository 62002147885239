import "./styles/App.css";
import Home from "./menus/homepage";
import Mining from "./menus/mining-equipment";
import Industrial from "./menus/industrial-machinery";
import Assessories from "./menus/accessories";
import About from "./menus/about-us";
import Error from "./components/error";
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact to element={<Home />} />
        <Route path="/mining-equipment" exact to element={<Mining />} />
        <Route path="/industrial-equipment" exact to element={<Industrial />} />
        <Route path="/mining-assessories" exact to element={<Assessories />} />
        <Route path="/about" exact to element={<About />} />
        <Route path="*" exact to element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
