export const pictures = [
  {
    id: "1",
    img: "/images/mine-cover.jpg",
    text: "We Are Yovan Trading, For All your Mining & Industrial Hardware Accessories.",
  },
  {
    id: "2",
    img: "/images/team.jpg",
    text: "We work together to archive, We believe in hard work",
  },
  {
    id: "3",
    img: "/images/load.jpg",
    text: "We have high quality mining machinery to bulldoze your grounds",
  },
];
