export const assessories_images = [
  {
    id: "1",
    img: "images/Charging Sticks 22mm,25mm,32mm,50mm.jpg",
    text: "Charging Sticks",
  },
  {
    id: "2",
    img: "images/Claw couplings 12mm, 20mm, 25mm.jpg",
    text: "Claw Couplings",
  },
  {
    id: "4",
    img: "images/Core Blocks.jpg",
    text: "Core Blocks",
  },
  {
    id: "5",
    img: "images/core trays AQ,BQ,NQ,HQ,PQ.jpg",
    text: "Core Trays",
  },
  {
    id: "6",
    img: "images/Coupling Sleeves.png",
    text: "Coupling Sleeves",
  },
  {
    id: "7",
    img: "images/g_cocks.png",
    text: "Glen Cocks",
  },
  {
    id: "8",
    img: "images/clamps.jpg",
    text: "Hose Clamps",
  },
  {
    id: "9",
    img: "images/Lubricans.jpg",
    text: "Lubricants",
  },
  {
    id: "10",
    img: "images/Mining Hoses 12mm,20mm,25mm,32mm,40mm,50mm.jpg",
    text: "Mining Hoses",
  },
  {
    id: "11",
    img: "images/Moil&Chisel.png",
    text: "Moil&Chisel",
  },
  {
    id: "12",
    img: "images/Other types of valves.jpg",
    text: "Other types of valves",
  },
  {
    id: "13",
    img: "images/Pipe Fittings.jpg",
    text: "Pipe Fittings",
  },
  {
    id: "14",
    img: "images/Quick Fit Fittings 12mm,20mm,25mm,40mm,50mm.png",
    text: "Quick Fit Fittings",
  },
  {
    id: "15",
    img: "images/Screw type fittings 12mm,20mm,25mm,40mm,50mm.png",
    text: "Screw type fittings",
  },
  {
    id: "16",
    img: "images/Underground spray mark.png",
    text: "Underground Spray Mark",
  },
  {
    id: "17",
    img: "images/Whipcheck 12-32mm&40mm-75mm.jpg",
    text: "Whipcheck",
  },
  {
    id: "18",
    img: "images/Spray Extenders.png",
    text: "Spray Extenders",
  },
  {
    id: "19",
    img: "images/cindlers.jpg",
    text: "Throughing Idlers / Return",
  },
  {
    id: "20",
    img: "images/screwdownvalve 25mm&50mm.jpg",
    text: "Screwdownvalve",
  },
  {
    id: "21",
    img: "images/Rock Tools.jpg",
    text: "Rock Tools",
  },
  {
    id: "22",
    img: "images/Manifolds.png",
    text: "Manifolds",
  },
  {
    id: "23",
    img: "images/jaw crasher.jpg",
    text: "Crasher Jaw",
  },
  {
    id: "24",
    img: "images/galvinised-wire.jpg",
    text: "Galvinised Wire",
  },
  {
    id: "25",
    img: "images/water blast.jpg",
    text: "Water Blast",
  },
  {
    id: "26",
    img: "images/Aluminium-Tubes.jpg",
    text: "Alminium Tubes",
  },
  {
    id: "27",
    img: "images/3 way cock valve.jpg",
    text: "3 way cock valve",
  },
  {
    id: "28",
    img: "images/air whistle.jpg",
    text: "Air whistle",
  },
  {
    id: "29",
    img: "images/Anti Static Hose 20mm.jpg",
    text: "Anti Static Hose",
  },
  {
    id: "30",
    img: "images/Compression Fittings.jpg",
    text: "Compression Fittings",
  },
  {
    id: "31",
    img: "images/AQ &BQ RODS.jpg",
    text: "AQ &BQ RODS",
  },
  {
    id: "32",
    img: "images/Extenstion-Rods.jpg",
    text: "Extension RODS",
  },
];
