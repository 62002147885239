export const mine_images = [
  {
    id: "1",
    img: "images/channel cutting machine.jpg",
    text: "Channel Cutting Machine",
  },
  {
    id: "2",
    img: "images/Diamond Crown bits and shells.jpg",
    text: "Diamond Crown Bits and Shells",
  },
  {
    id: "3",
    img: "images/Kemp Machine U2, U39B, U39BQ.jpg",
    text: "Kemp Machine",
  },
  {
    id: "4",
    img: "images/Lever Remote Control Panel.jpg",
    text: "Lever Remote Control Panel",
  },
  {
    id: "7",
    img: "images/Retractable Airlegs.jpg",
    text: "Retractable Airlegs",
  },
  {
    id: "8",
    img: "images/Taper Bit.png",
    text: "Taper Bits",
  },
  {
    id: "9",
    img: "images/Meter eater AQ&BQ.jpg",
    text: "Meter Eater AQ&BQ",
  },
  {
    id: "10",
    img: "images/pumps.png",
    text: "Pumps",
  },
  {
    id: "11",
    img: "images/drifter.jpg",
    text: "MPD120 Drifter",
  },
  {
    id: "12",
    img: "images/rockdrill machine s25&s215.jpg",
    text: "Rockdrill Machine s25&s215",
  },
  {
    id: "13",
    img: "images/s36 drifter.jpg",
    text: "s36 Drifter",
  },
  {
    id: "14",
    img: "images/reaming-bit.jpg",
    text: "Reamer Bits",
  },
  {
    id: "14",
    img: "images/Shank Adapter.png",
    text: "Shank Adapter",
  },
  {
    id: "15",
    img: "images/taper rods.jfif",
    text: "Taper Steels",
  },
];
