import NavBar from "../components/nav";
import Mequipment from "../components/mining-equp";
import About from "../components/footer";
const Mining = () => {
  return (
    <>
      <NavBar />
      <Mequipment />
      <About />
    </>
  );
};

export default Mining;
